body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box,
#fromio .formio-form .wizard-page {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px -3px 12px #7278820f;
}

#fromio .formio-form .wizard-page h1,
#fromio .formio-form .wizard-page h2,
#fromio .formio-form .wizard-page h3,
#fromio .formio-form .wizard-page h4,
#fromio .formio-form .wizard-page h5,
#fromio .formio-form .wizard-page h6 {
  margin: 0;
  padding: 0;
}

#fromio .formio-form {
  font-family: 'Roboto', 'sans-serif' !important;
}

#fromio .formio-form .wizard-page {
  padding-bottom: 65px;
}

#fromio .formio-form > div:first-child {
  display: grid;
  grid-template-columns: 20% 80%;
}

.step {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: cream;
}

.v-stepper {
  position: relative;
}

/* regular step */
.step .circle {
  background-color: white;
  border: 2px solid #d4d8dd;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.step .line {
  top: 20px;
  left: 10px;
  height: 100%;
  position: absolute;
  border-left: 2px dotted #d4d8dd;
}

.step.completed .circle {
  visibility: visible;
  background-color: #3487f3;
  background-image: url('assets/icons/check.svg');
  border-color: #3487f3;
}

.step.completed .line {
  border-left: 2px dotted #3487f3;
}

.step.active .circle {
  visibility: visible;
  border-color: #3487f3;
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 2px dotted white;
  z-index: -1; /* behind the circle to completely hide */
}

.wizard-nav-container .content {
  margin-left: 20px;
  display: inline-block;
  text-align: left;
  letter-spacing: 0px;
  color: #6b727d;
  opacity: 1;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
}

.wizard-nav-container .completed .content {
  color: #6b727d;
}
.wizard-nav-container .active .content {
  color: #39404b;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Roboto';
}

.wizard-nav-container {
  background: #fff;
  border-radius: 8px;
  width: 90%;
  min-height: 380px;
  padding: 30px 20px;
  margin-left: 0;
}

.formio-form .formio-wizard-nav-container {
  position: absolute;
  right: 12px;
  bottom: 5px;
}

.formio-form .btn-wizard-nav-cancel,
.formio-form .btn-wizard-nav-submit {
  display: none;
}

/* File Uploader */
.fileUploader {
  min-width: 100% !important;
  height: 400px !important;
  border: dashed 2px #18aee2 !important;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Roboto', 'sans-serif' !important;
}

.fileUploader.active {
  border: dashed 2px #1e9d66 !important;
}

.fileUploader .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fileUploader .fileWarpper {
  width: 128px;
  height: 128px;
  border-radius: 8px;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

.fileUploader .fileWarpper img {
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.fileUploader .fileWarpper.active {
  background: #1e9d66;
  color: #fff;
}

/* rotate animation */

.loading {
  animation: animName 2s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.css-1ss2ei0-MuiContainer-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.rdw-editor-wrapper {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
}

.rdw-editor-main {
  padding: 0 15px;
}

.MuiStepLabel-labelContainer span {
  font-size: 13px;
}
.choices__list--dropdown, .choices__list[aria-expanded]{
overflow: scroll !important;
  height: 200px !important;
}
.formio-component-datagrid{
  height: 350px !important;
}

.css-1vooibu-MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;

}
.css-1cw4hi4 {
  user-select: none;
  width: 1.5em !important;
  height: 1.5em !important; 
}

.formio-choices.form-group.is-open, .formio-choices.formio-form-group {
  width: 480px !important;
}
.formio-choices.form-group, .formio-choices.formio-form-group {
  width: 220px !important;
}